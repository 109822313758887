import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea } from '@material-ui/core';
import houseLogo from './../cpcc-house.svg';

const useStyles = makeStyles(theme => ({
  houseLogo: {
    height: "15vmin"
  }
}));

function LogoCardSimple() {
  const classes = useStyles();  

  return (    
    <Card>
      <CardActionArea href="/#/home" >
        <img src={houseLogo} className={classes.houseLogo} alt="logo" />
      </CardActionArea>
    </Card>
  );
}
export default LogoCardSimple;